<template>
<div ref="editor" />
</template>

<script>
import Editor from '@toast-ui/editor';
import { uploadProjectImage, ImageType } from '@/utils/api';

import '@toast-ui/editor/dist/toastui-editor.css';

export default {
  name: 'markdown-editor',
  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      editor: null,
      markdown: '',
    };
  },
  mounted() {
    this.editor = new Editor({
      el: this.$refs.editor,
      initialEditType: 'wysiwyg',
      previewStyle: 'vertical',
      hideModeSwitch: true,
      height: `${this.height}px`,
      initialValue: this.value,
      placeholder: this.placeholder,
      hooks: {
        addImageBlobHook: (blob, callback) => {
          uploadProjectImage({ projectId: this.projectId, type: ImageType.DESCRIPTION, file: blob })
            .then((resp) => {
              const { url } = resp.data.resource;
              callback(url, null);
            })
            .catch((err) => {
              console.log('image upload err: ', err);
            });
          return false;
        },
      },
    });
    this.editor.on('change', () => {
      const newValue = this.editor.getMarkdown();
      if(this.value !== newValue) {
        this.$emit('input', newValue);
      }
    });
  },
};
</script>

<style lang="scss">
@import 'general';

.tui-editor-contents {
  @include p_large;
}
</style>
