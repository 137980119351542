<template>
<div class="milestone">
  <div class="bullet-wrap">
    <div
      class="bullet-line"
      :class="{ first, last, only }"
    />
    <div class="bullet">
      <Bullet :color="first ? '#2E40EA' : '#000'" />
    </div>
  </div>
  <div class="milestone-info" :class="{ hover }">
    <div v-if="editable" class="milestone-edit-wrap">
      <div class="milestone-delete" @click="$emit('delete')">
        <img :src="require('@/static/img/icons/ic-trash.png')">
      </div>
      <div class="milestone-edit" @click="$emit('edit')">
        <img :src="require('@/static/img/icons/ic-edit.png')">
      </div>
    </div>
    <div v-if="milestone.formattedDate" class="date">
      {{ milestone.formattedDate }}
    </div>
    <div v-if="milestone.date" class="date">
      {{ milestone.date.format('MMM DD') }}
    </div>
    <h4>{{ milestone.name }}</h4>
    <CollapsibleText
      :text="milestone.description"
      textClass="desc"
      :showMore="false"
    />
    <hr v-if="showRelease">
    <div v-if="showRelease" class="release">
      <Lock color="#2e40ea" />
      <div class="release-text">
        {{ $tc('dashboard.timeline.funds_raised', milestone.release_percent) }}
      </div>
      <div
        v-if="completable"
        class="mark-complete"
        @click="$emit('mark-complete')"
      >
        {{ $t('manager.timeline.modal.button') }}
      </div>
    </div>
  </div>
</div>
</template>

<script>
import Bullet from '@/components/svg/Bullet.vue';
import Lock from '@/components/svg/Lock.vue';
import CollapsibleText from '@/components/widget/CollapsibleText';

export default {
  name: 'milestones',
  components: {
    Bullet,
    Lock,
    CollapsibleText,
  },
  props: {
    milestone: {
      type: Object,
      default: null,
    },
    first: Boolean,
    last: Boolean,
    only: Boolean,
    hover: Boolean,
    completable: Boolean,
    editable: Boolean,
  },
  computed: {
    showRelease() {
      return !!this.milestone.release_percent;
    },
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.milestone {
  display: flex;
  flex-direction: row;
  width: 100%;
  .bullet-wrap {
    position: relative;

    .bullet-line {
      width: 10px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: -31px;
      border-left: 1px solid $border-light;

      &.first {
        top: 40px;
      }
      &.last {
        bottom: calc(100% - 35px);
      }
      &.only {
        display: none;
      }
    }

    .bullet {
      position: absolute;
      top: 20px;
      left: -40px;
    }
  }
  .milestone-info {
    width: 100%;
    padding: 16px 24px 16px 24px;
    border: 1px solid $border-lighter;
    position: relative;
    .milestone-edit-wrap {
      position: absolute;
      top: 24px;
      right: 24px;
      display: flex;
      .milestone-edit, .milestone-delete {
        background: $border-lighter;
        padding: 1px 2px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .milestone-edit {
        margin-left: 3px;
        img {
          width: 29px;
        }
      }
      .milestone-delete {
        img {
          width: 27px;
        }
      }
    }
    hr {
      margin: 0;
    }
    &.hover:hover {
      background-color: $white;
      transition: all $speed3 ease;
    }
    .date {
      @include subheading;
      color: $grey50;
    }
    .desc {
      @include p_small;
      margin: 8px 0 8px;
    }
    .release {
      @include button_large_text;
      margin-top: 8px;
      display: flex;
      align-items: center;
      svg {
        margin-right: 10px;
      }
      .release-text {
        margin-top: 7px;
      }
      .mark-complete {
        @include blue-button($type: secondary);
        margin-left: auto;
        margin-top: 4px;
      }
    }
  }
}
</style>
