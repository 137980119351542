<template>
<div
  class="p-radio"
  :class="{
    'selectable-input' : selectableInput,
    'hidden-radio' : hiddenRadio,
    checked: value === radioValue,
  }"
>
  <input
    :id="inputId"
    type="radio"
    :name="radioName"
    :value="radioValue"
    :checked="value === radioValue"
  >
  <label :for="inputId" @click="$emit('input', radioValue)">
    <div class="p-radio-content">
      <slot />
    </div>
  </label>
</div>
</template>

<script>
/**
 * Example: <Radio :radioValue="a" v-model="someValue" />
 */
export default {
  name: 'radio',
  props: {
    value: {
      type: [String, Number],
      required: true,
    },
    radioValue: {
      type: [String, Number],
      default: null,
    },
    radioID: {
      type: String,
      default: '',
    },
    radioName: {
      type: String,
      default: 'name',
    },
    selectableInput: {
      type: Boolean,
      default: false,
    },
    hiddenRadio: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    inputId() {
      return this.radioId || this.radioValue;
    },
  },
};
</script>

<style lang="scss">
@import 'widgets';

.selectable-input {
  @include input;
  display: flex;
  align-items: center;

  label {
    width: 100%;
  }
}
.p-radio-content {
  margin-left: 16px;
}

.p-radio {
  @include radio;
  label {
    font-family: $font-title;
  }
}
.hidden-radio.p-radio label {
  margin-left: -16px;
  &::before {
    display: none;
  }
}

</style>
