<template>
<transition name="fade">
  <div class="manage-team-modal-wrap">
    <ModalHeader :quitText="$t('close')" @close-modal="$emit('close-modal')" />
    <div class="manage-team-content">
      <h2>{{ $t('dashboard.manage') }}</h2>
      <div class="manage-team-body">
        <h4>{{ $t('dashboard.manage_team.collaborators') }}</h4>
        <div>
          {{ $t('dashboard.manage_team.invite') }}
        </div>
        <div class="manage-team-invite">
          <input
            v-model="inviteEmail"
            :placeholder="$t('dashboard.manage_team.email')"
            @input="handleEmailValidation"
          >
          <TitledSelect
            v-model="inviteRole"
            type="select"
            :options="roleArray"
          >
            <template slot="singleLabel" slot-scope="{ option }">
              <div>
                {{ option.title }}
              </div>
            </template>
            <template slot="option" slot-scope="props">
              <div class="option__title">
                {{ props.option.title }}
              </div>
              <div class="option__desc">
                {{ props.option.desc }}
              </div>
            </template>
          </TitledSelect>
          <PButton
            class="manage-team-invite-send"
            :primary="false"
            color="#2e40ea"
            :animate="inviteAnimate"
            @click="sendInvite"
          >
            {{ $t('dashboard.manage_team.send') }}
          </PButton>
        </div>
        <ErrorMessage
          v-if="inviteEmailError"
          :errorMessage="inviteEmailError"
        />
        <h4 class="manage-team-title">
          {{ $t('dashboard.manage_team.team') }}
        </h4>
        <div class="manage-team-member creator">
          <Avatar :user="project.creator">
            <div class="team-email">
              {{ project.creator.name }}
            </div>
          </Avatar>
          <div class="invite-status">
            <div class="team-role">
              {{ $t('creator') }}
            </div>
          </div>
        </div>
        <div
          v-for="(member, index) in team"
          :key="index"
          class="manage-team-member"
        >
          <Avatar :user="member.user">
            <div class="team-email">
              {{ member.email || member.user.name }}
            </div>
          </Avatar>
          <div class="invite-status">
            <div class="invite-sent">
              {{ $t('dashboard.manage_team.invite_sent') }}
            </div>
            <div class="team-role">
              {{ $t(`dashboard.manage_team.roles.${member.role}.title`) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</transition>
</template>

<script>
import ModalHeader from '@/components/widget/ModalHeader.vue';
import PButton from '@/components/widget/PButton.vue';
import TitledSelect from '@/components/widget/TitledSelect.vue';
import ErrorMessage from '@/components/widget/ErrorMessage.vue';
import Avatar from '@/components/widget/Avatar.vue';
import { inviteCollaborator, getCollaborators, getCollaboratorInvites } from '@/utils/api';
import { fieldErrorKey } from '@/utils/apiError';
import { validateEmail } from '@/utils/stringUtils';

export default {
  name: 'manage-team-modal',
  components: {
    Avatar,
    ModalHeader,
    PButton,
    TitledSelect,
    ErrorMessage,
  },
  props: {
    project: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const roles = this.$t('dashboard.manage_team.roles');
    return {
      inviteEmail: '',
      inviteRole: this.$t('dashboard.manage_team.roles')[0],
      inviteEmailError: null,
      collaborators: [],
      collaboratorInvites: [],
      inviteAnimate: false,
      roleArray: Object.keys(roles).map(key => ({ key, ...roles[key] })),
    };
  },
  computed: {
    team() {
      return [
        ...this.collaboratorInvites,
        ...this.collaborators,
      ];
    },
  },
  methods: {
    async sendInvite() {
      if(!this.inviteEmailError) {
        try {
          this.inviteAnimate = true;
          await inviteCollaborator(this.project.id, this.inviteEmail, this.inviteRole.key);
          this.inviteEmail = '';
          await this.loadCollaboratorData();
        } catch(e) {
          this.inviteEmailError = this.$t(fieldErrorKey(e.errors));
        }
        this.inviteAnimate = false;
      }
    },
    async loadCollaboratorData() {
      try {
        const { data: { collaborators } } = await getCollaborators(this.project.id);
        this.collaborators = collaborators;
        const { data: { invites } } = await getCollaboratorInvites(this.project.id);
        this.collaboratorInvites = invites;
      } catch(error) {
        console.log(error, 'error');
      }
    },
    handleEmailValidation(ev) {
      const inviteEmail = ev.target.value;
      if(!validateEmail(inviteEmail)) {
        this.inviteEmailError = this.$t('errors.INVALID_VALUE.email');
      } else {
        this.inviteEmailError = null;
      }
    },
  },
  created() {
    this.loadCollaboratorData();
  },
};
</script>

<style lang="scss">
@import 'general';
@import 'widgets';

.manage-team-modal-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-color: $white;
  z-index: 2000;
  padding: 32px 50px 72px;

  .manage-team-content {
    max-width: 600px;
    margin: 24px auto 0 auto;

    h2 {
      text-align: center;
    }
    .manage-team-body {
      margin-top: 48px;
    }
    .manage-team-invite {
      display: flex;
      margin-top: 24px;
      input {
        @include input;
        border-right: none;
      }
      .p-select-wrap {
        width: 200px;
        min-width: 200px;
        height: 48px;
        margin: 0;
        .option__desc {
          @include p-info-small;
          color: $main-black;
        }
      }
      .manage-team-invite-send.p-button {
        @include button_small_text;
        border: 1px solid $border-light;
        border-left: none;
        height: 48px;
        text-align: center;
        background: $white;
        color: $main-blue;
        cursor: pointer;
        padding: 4px 0 0;
        .p-button-animate .bars .bar {
          background-color: $main-blue;
        }
      }
    }
    .manage-team-title {
      margin: 48px 0 16px;
    }
    .manage-team-member {
      display: flex;
      align-items: center;
      border-top: 1px solid $border-light;
      height: 80px;
      @include h5;
      &:last-child {
        border-bottom: 1px solid $border-light;
      }
      .team-email {
        margin-left: 16px;
        display: flex;
        align-items: center;
      }
      .avatar .avatar-img-wrap .avatar-img {
        width: 32px;
        height: 32px;
      }
      .invite-status {
        display: flex;
        margin-left: auto;
      }
      .invite-sent {
        color: $grey50;
      }
      .team-role {
        color: $main-blue;
        width: 90px;
        text-align: right;
      }
      a:hover {
        color: $main-black;
        cursor: unset;
      }
      a.link-enabled:hover {
        color: $main-blue;
        cursor: pointer;
      }
      &.creator {
        .invite-sent {
          display: none;
        }
        .team-role {
          color: $main-black;
        }
      }
    }
  }
  @media (max-width: 640px) {
    .manage-team-content {
      .manage-team-invite {
        flex-wrap: wrap;
        input {
          width: unset;
        }
        .p-select-wrap {
          width: 140px;
          min-width: 140px;
        }
        .manage-team-invite-send.p-button {
          width: 100%;
          min-width: unset;
        }
      }
    }
  }
  @media (max-width: $mobile-width) {
    .manage-team-content {
      .manage-team-invite {
        flex-direction: column;
        input {
          width: 100%;
          border: 1px solid $border-light;
        }
        .p-select-wrap {
          width: 100%;
          margin-top: 16px;
        }
        .manage-team-invite-send {
          border: 1px solid $border-light;
          margin-top: 16px;
          width: 100%;
        }
      }
      .manage-team-member {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        .invite-status {
          margin-left: 48px;
        }
        .invite-sent {
          margin-right: 24px;
        }
        .team-role {
          width: auto;
        }
      }
    }
  }
}

</style>
